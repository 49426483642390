<template>
  <el-dropdown :hide-on-click="true" @command="handleCommand">
    <span class="el-dropdown-link df ai-c jc-c">
      <div
        style="
          margin-right: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <i
          class="el-icon-sort"
          style="color: black; font-size: 16px; font-weight: 700"
        />
      </div>

      <span style="color: black; font-size: 14px; font-weight: 500">{{ label }}</span>
      <i class="el-icon-caret-bottom" />
    </span>
    <el-dropdown-menu slot="dropdown" :teleported="false">
      <el-dropdown-item
        v-for="option in options"
        :key="option.value"
        :command="option.value"
        :is-active="option.value === chosen"
      >
        {{ option.label }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  data() {
    return {
      chosen: '-time',
      options: [
        {
          value: '-time',
          label: 'По дате (сначала новые)',
        },
        {
          value: 'time',
          label: 'По дате (сначала старые)',
        },
      ],
    }
  },
  computed: {
    label() {
      return this.options.find(item => item.value === this.chosen).value === '-time' ? 'Сначала новые' : 'Сначала старые'
    },
  },
  methods: {
    handleCommand(command) {
      this.chosen = command
      this.$emit('handleChoseSort', command)
    },
  },
}
</script>

<style lang="sass" scoped>
.text-select
  >.el-input__inner
    border: none!important
</style>
