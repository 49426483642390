var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    { attrs: { "hide-on-click": true }, on: { command: _vm.handleCommand } },
    [
      _c("span", { staticClass: "el-dropdown-link df ai-c jc-c" }, [
        _c(
          "div",
          {
            staticStyle: {
              "margin-right": "4px",
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
            },
          },
          [
            _c("i", {
              staticClass: "el-icon-sort",
              staticStyle: {
                color: "black",
                "font-size": "16px",
                "font-weight": "700",
              },
            }),
          ]
        ),
        _c(
          "span",
          {
            staticStyle: {
              color: "black",
              "font-size": "14px",
              "font-weight": "500",
            },
          },
          [_vm._v(_vm._s(_vm.label))]
        ),
        _c("i", { staticClass: "el-icon-caret-bottom" }),
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown", teleported: false }, slot: "dropdown" },
        _vm._l(_vm.options, function (option) {
          return _c(
            "el-dropdown-item",
            {
              key: option.value,
              attrs: {
                command: option.value,
                "is-active": option.value === _vm.chosen,
              },
            },
            [_vm._v(" " + _vm._s(option.label) + " ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }